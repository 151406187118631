import { Injectable } from '@angular/core';
import { DataApiService, DataOptions, HttpOptions, BaseService } from '@xpo-ltl/data-api';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

import {
	GetAppMetadataResp,
	GetAppMetadataPath,
	ListApplicationsResp
} from './api-appmetadata';

import {
	GetHealthCheckResp,
	User
} from '@xpo-ltl/sdk-common';

import { Observable } from 'rxjs';

@Injectable()
export class AppMetadataApiService extends BaseService {
	private static AppMetadataApiEndpoint = 'appmetadataApiEndpoint';

	constructor(private dataApiService: DataApiService, private configManager: ConfigManagerService) {
		super();
	}

	/**
	* Health check URL. Responds with success message if the service is running.
	*/
	public healthCheck(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetHealthCheckResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/health-check'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* List of resources.
	*/
	public options(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/options'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Details of user invoking the API.
	*/
	public loggedInUser(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<User> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/appusers/logged-in-user'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Get metadata about and app.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. name of app is required.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. MinimumAppVersion.
	* <br/>2. List of supported browsers.
	*/
	public getAppMetadata(
							   pathParams: GetAppMetadataPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetAppMetadataResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/apps/{appName}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Get list of applications
	* <br/>
	* <br/>Pre-conditions:
	* <br/>None
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. List of applications with details such as name, supported browsers, minimum app version etc.
	* <br/>
	*/
	public listApplications(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListApplicationsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/applications'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}


	protected getEndPoint(): string {
		return this.configManager.getSetting(AppMetadataApiService.AppMetadataApiEndpoint);
	}
}
