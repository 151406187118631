import {ModuleExecutorRunStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class ModuleExecutorRunStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ModuleExecutorRunStatusCd ) {
      this._values.push(ModuleExecutorRunStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ModuleExecutorRunStatusCd {

    for ( const obj in ModuleExecutorRunStatusCd ) {
      if (ModuleExecutorRunStatusCd[obj] === value){
        return ModuleExecutorRunStatusCd[obj] as ModuleExecutorRunStatusCd;
      }
    }
  }
}

const ModuleExecutorRunStatusCdHelper = new ModuleExecutorRunStatusCdHelperEnumHelperClass();
export default ModuleExecutorRunStatusCdHelper;
