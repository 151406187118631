/**
 * AppMetadata API
 * Version: 1.0
 * Build: Manual
 */


/**
 * Request parameters for getAppMetadata.
 */
export class GetAppMetadataRqst {
	/**
	 * Required: false
	 * Array: false
	 */
	appName:string = undefined;
}
/**
 * Contains information about an app such as minimum version, supported browsers and version etc.
 */
export class GetAppMetadataResp {
	/**
	 * The name of the app
	 * Required: false
	 * Array: false
	 */
	appName:string = undefined;
	/**
	 * The minimum supported app version
	 * Required: false
	 * Array: false
	 */
	minAppVersion:string = undefined;
	/**
	 * List of supported browsers
	 * Required: false
	 * Array: true
	 */
	supportedBrowsers:Browser[] = undefined;
}
/**
 * List of applications
 */
export class ListApplicationsResp {
	/**
	 * Required: false
	 * Array: true
	 */
	applications:Application[] = undefined;
}
export class Application {
	/**
	 * Required: false
	 * Array: false
	 */
	appName:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	url:string = undefined;
	/**
	 * The minimum supported app version
	 * Required: false
	 * Array: false
	 */
	minAppVersion:string = undefined;
	/**
	 * List of supported browsers
	 * Required: false
	 * Array: true
	 */
	supportedBrowsers:Browser[] = undefined;
}
/**
 * Contains information about browser.
 */
export class Browser {
	/**
	 * Name of browser
	 * Required: false
	 * Array: false
	 */
	name:string = undefined;
	/**
	 * The minimum supported browser version
	 * Required: false
	 * Array: false
	 */
	minVersion:string = undefined;
}


/**
 * Path parameters for getAppMetadata
 */
export class GetAppMetadataPath {
	appName: string = undefined;
}


